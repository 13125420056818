:root {
  --hrp-base: #ffffff;

  --hrp-subtle-900: #061A2D;
  --hrp-subtle-800: #495969;
  --hrp-subtle-700: #607385;
  --hrp-subtle-600: #8593A0;
  --hrp-subtle-500: #A6B3BF;
  --hrp-subtle-400: #D3D9DF;
  --hrp-subtle-300: #E7EBEE;
  --hrp-subtle-200: #F3F4F6;
  --hrp-subtle-100: #FBFCFD;

  --hrp-primary-900: #001D66;
  --hrp-primary-700: #1441B3;
  --hrp-primary-500: #3F6EE6;
  --hrp-primary-300: #C7D5FF;
  --hrp-primary-100: #EBF0FF;

  --hrp-accent-900: #661B00;
  --hrp-accent-700: #993B00;
  --hrp-accent-500: #F9703E;
  --hrp-accent-300: #FFBB99;
  --hrp-accent-100: #FFF3EB;

  --hrp-error-900: #5C0100;
  --hrp-error-700: #A30300;
  --hrp-error-500: #F70400;
  --hrp-error-300: #FF9B99;
  --hrp-error-100: #FFEBEB;

  --hrp-warning-900: #663A00;
  --hrp-warning-700: #C77905;
  --hrp-warning-500: #FACB5F;
  --hrp-warning-300: #FFE9B8;
  --hrp-warning-100: #FFF9EB;

  --hrp-success-900: #113201;
  --hrp-success-700: #1C5002;
  --hrp-success-500: #236303;
  --hrp-success-300: #C1EDAB;
  --hrp-success-100: #F2FDEC;

  --hrp-secondary-900: #003330;
  --hrp-secondary-700: #005C57;
  --hrp-secondary-500: #008079;
  --hrp-secondary-300: #99E5E1;
  --hrp-secondary-100: #DBFFFD;

  --hrp-tertiary-900: #3B0570;
  --hrp-tertiary-700: #621DA5;
  --hrp-tertiary-500: #8049B6;
  --hrp-tertiary-300: #CCB0E8;
  --hrp-tertiary-100: #F5ECFE;

  --hrp-disabled: #EBEAEF;
  --hrp-disabled-content:#8593A0;


  --hrp-shadow-small:  0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 2px 0px rgba(0, 0, 0, 0.24);
  --hrp-shadow-medium: 0px 4px 6px 0px rgba(0, 0, 0, 0.15), 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  --hrp-shadow-card-1: 0px 5px 15px 0px rgba(0, 0, 0, 0.10), 0px 3px 5px 0px rgba(0, 0, 0, 0.05);
  --hrp-shadow-card-2: 0px 10px 20px 0px rgba(0, 0, 0, 0.15), 0px 3px 6px 0px rgba(0, 0, 0, 0.03);
  --hrp-shadow-dialog: 0px 15px 35px 0px rgba(0, 0, 0, 0.20);
  --hrp-shadow-tooltip: 0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 4px 7px 0px rgba(0, 0, 0, 0.25);
  --hrp-shadow-overlay: 0px -5px 15px 1px rgba(0, 0, 0, 0.20), 0px -3px 10px 1px rgba(0, 0, 0, 0.10);
  --hrp-shadow-overlay-left: 5px 0px 15px 1px rgba(0, 0, 0, 0.10), 3px 0px 10px 1px rgba(0, 0, 0, 0.05);
  --hrp-shadow-inner: 0px 1px 4px 0px rgba(0, 0, 0, 0.15) inset;





  // For UI Library

  --par-base: var(--hrp-base);
  --par-base-content: var(--hrp-subtle-900);

  --par-subtle-300: var(--hrp-subtle-800);
  --par-subtle-200: var(--hrp-subtle-700);
  --par-subtle-100: var(--hrp-subtle-200);
  --par-subtle-50: var(--hrp-subtle-100);

  --par-primary-300: var(--hrp-primary-700);
  --par-primary-200: var(--hrp-primary-500);
  --par-primary-100: var(--hrp-primary-300);

  --par-disabled: var(--hrp-disabled);
  --par-disabled-content: var(--hrp-disabled-content);

  --par-error-content: var(--hrp-error-700);
  --par-error: var(--hrp-error-100);

  --par-success-content: var(--hrp-success-700);
  --par-success: var(--hrp-success-100);

  --par-warning-content: var(--hrp-warning-700);
  --par-warning: var(--hrp-warning-500);
  --par-warning-base: var(--hrp-warning-100);

  --par-border-radius-input: 0.3125rem;
  --par-border-radius-button: 0.3125rem;
  --par-border-radius-checkbox: 0.3125rem;

  --par-font-family: "Open Sans";



}
